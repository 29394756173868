import GameCard from "../GameCard/GameCard";
import characterHelper from "../../helpers/characterHelper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { replaceYNWithNameOfPlayer, addUnicode } from "../../helpers/customStringHelpers";
import { useDispatch, useSelector } from "react-redux";
import { resetAction } from "../../redux/actions/hints";
const DialogueCard = ({ data }) => {
  const [dialogue, setDialogue] = useState(1);
  const [ended, setEnded] = useState(false);
  const [text, setText] = useState("");
  const [character, setCharacter] = useState(null)
  const [filter, setFilteredDialogue] = useState([]);
  const [typer, setTyper] = useState(0);
  const [buttonText, setButtonText] = useState("Next");
  const uN = useNavigate();
  const nameOfPlayer = useSelector((state) => state.nameOfPlayer);
  const uD = useDispatch();

  useEffect(() => {
    if (ended === true) {
      setEnded(false);
      setDialogue(1);
      uN(data.dialogues[data.dialogues.length - 1].next);
    }
  }, [ended]);

  const nextDialogue = () => {
    if(dialogue === data.dialogues.length -1){
      setButtonText("Continue");
    }
    if(dialogue <= data.dialogues.length - 1){
      setDialogue(dialogue + 1);
      setTyper(0);
      return;
    } 
    setEnded(true);
  }

  useEffect(() => {
    uD(resetAction());

    let dialogueFiltered = data.dialogues.filter((d) => {
      return d.number === dialogue;
    });

    dialogueFiltered[0]["text"] = replaceYNWithNameOfPlayer(dialogueFiltered[0]["text"], nameOfPlayer);

    setFilteredDialogue(dialogueFiltered);
  }, [data, dialogue])

  useEffect(() => {

    let text = filter.map((dialogue) => {
      return (
        <>
          <span>{`${dialogue.character}:`} </span>
          <p>{Array.from(dialogue.text.slice(0, typer))}</p>
        </>
      );
    });

    let typerInterval = setInterval(() => {
      setTyper(typer + 1);
    }, 12)

    let character = filter.map((d) => {
      return <img src={characterHelper(d.characterImage).img} alt="" />;
    });

    setCharacter(character);
    setText(text);

    return () => clearInterval(typerInterval);
  }, [filter, typer])

  const sectionClicked = () => {
    if (typer < filter[0].text.length) {
      setTyper(filter[0].text.length);
      return;
    }
  };

  return (
    <GameCard key={data.progress} data={data}>
      <section onClick={sectionClicked} className="gameCard__dialogue">
        <figure className="gameCard__character">{character}</figure>
        <p>{text}</p>
        <div className="gameCard__buttonWrapper">
          <button onClick={nextDialogue}className="submit">{buttonText}</button>
        </div>
      </section>
    </GameCard>
  );
};

export default DialogueCard;
