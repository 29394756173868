import {Link} from "react-router-dom";
const PrimaryButton = () => {
  return (
    <Link
      className="heroSection__button heroSection__button--primary"
      to="/tips"
    >
      <p>Play year 2</p>
    </Link>
  );
};

export default PrimaryButton;
