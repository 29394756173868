import Background from "../../components/background/Background";
import GameHeader from "../../components/GameHeader/GameHeader";
import GameButtons from "../../components/GameButtons/GameButtons";
import Footer from "../../components/footer/Footer";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Lumos from "./Lumos";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { transformToUppercase, removeSpaces } from "../../helpers/customStringHelpers";
import OverlayWrapper from "../../components/OverlayWrapper/OverlayWrapper";
import Duel from "./Duel";
import Cauldron from "./Cauldron";
const Interactive = ({ data }) => {
  let interactiveComponent;
  const [answer,setAnswer] = useState("");
  const [errorOpen, setErrorOpen] = useState("closed");
  const uN = useNavigate();
  const uD = useDispatch();
  uD({type: "HINTS", payload: data.hints});
  const checkAnswer = () =>{
    if(removeSpaces(transformToUppercase(answer)) === removeSpaces(transformToUppercase(data.answer))){
      setAnswer("");
      uN(data.next);
    }
    else{
      setErrorOpen("open")
    }
  }

  const setAnswerFromInput = (input) => {
    setAnswer(input);
  } 

  const closeDialogue = () =>{
    setErrorOpen("closed")
  }


  const openDialogue = () => {
    setErrorOpen("open");
  }

  switch (data.interactiveTitle) {
    case "LUMOS":
      interactiveComponent = <Lumos answer={answer} setAnswerFromInput={setAnswerFromInput} checkAnswer={checkAnswer} data={data}/>
      break;
    case "DUEL":
      console.log(data);
      interactiveComponent = <Duel data={data}></Duel>
      break;
    case "CAULDRON":
      interactiveComponent = <Cauldron data={data} openDialogue={openDialogue}></Cauldron>
      break;
    default:
      interactiveComponent = null;
  }




  return (
    <>
      <Background></Background>
      <OverlayWrapper></OverlayWrapper>
      <GameHeader data={data}></GameHeader>
      <div className="chapterWrapper">
        {interactiveComponent}
        <GameButtons></GameButtons>
      </div>
      <ErrorMessage closeDialogue={closeDialogue} ptr={data} eOpen={errorOpen}></ErrorMessage>
      <Footer extraClass="footer--game"></Footer>
    </>
  );
};

export default Interactive;
