import Background from "../../components/background/Background";
import GameHeader from "../../components/GameHeader/GameHeader";
import GameButtons from "../../components/GameButtons/GameButtons";
import Footer from "../../components/footer/Footer";
import ItemCard from "../../components/ItemCard/ItemCard";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import OverlayWrapper from "../../components/OverlayWrapper/OverlayWrapper";
const ItemObtained = ({ data }) => {
  console.log(data);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(data);
    if (data.type) {
      switch (data.type.name) {
        case "PARCHMENT":
          dispatch({ type: "PARCHMENTOBTAINED", payload: data.type.number });
          break;
        case "POTION":
          dispatch({type: "POTIONOBTAINED"});
          break;
      }
    }
  });
  return (
    <>
      <Background></Background>
      <OverlayWrapper></OverlayWrapper>
      <GameHeader data={data}></GameHeader>
      <div className="chapterWrapper">
        <ItemCard data={data} />
        <GameButtons></GameButtons>
      </div>

      <Footer extraClass="footer--game"></Footer>
    </>
  );
};

export default ItemObtained;
