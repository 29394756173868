import { useState } from "react";
import Background from "../../components/background/Background";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import "./AboutPage.scss";
import MobileNavigation from "../../components/mobileNavigation/MobileNavigation";
import FloatingActionButton from "../../components/fab/FloatingActionButton";
import { enableScroll } from "../../helpers/scrollHelpers";
import PageWrapper from "../../components/pageWrapper/PageWrapper";
import AboutSection from "../../components/AboutSection/AboutSection";
const AboutPage = () => {
  const [openNavigation, setOpenNavigation] = useState(false);

  const mobileNavButtonClicked = (open) => {
    enableScroll("auto", document.getElementsByTagName("body")[0]);
    setOpenNavigation(open);
  };

  if (openNavigation) {
    return <MobileNavigation setOpenNavigation={mobileNavButtonClicked} />;
  }

  return (
    <>
      <Background />
      <PageWrapper>    
        <FloatingActionButton />
        <Navigation setOpenNavigation={setOpenNavigation} />
        <AboutSection/>

        <Footer />
      </PageWrapper>
    </>
  );
};

export default AboutPage;
