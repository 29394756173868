const merge = [
  {
    number: 1,
    puzzle: 1,
    title: "Runes: Puzzle 1",
    background: "dark",
    answer: "LUMOS",
    next:"/interactive/1",
    progress: 17,
    character: "Terry",
    text: "[Y/N], why don’t you cast a wand-lighting charm?",
    errorMessage: ["TERRY", "“You’re sure that you’re in your second year right? Okay, just checking.”"],
    hints:[
      {
        title:"I just want the answer!",
        text: "I thought you would have learned this in year 1? The wand-lighting charm is called ‘Lumos’."
      }
    ]
  },
  {
    number: 2,
    puzzle: 2,
    title: "Coins: Puzzle 1",
    next:"/dialogue/9",
    background: "library--note",
    img: "PUZZLE8",
    answer: "157",
    character: "ANNYA",
    progress: 32,
    text: "Guys, look, a note just fell out of the book. Please tell me that someone can make sense of this? What do you reckon, [Y/N] ?",
    errorMessage: ["ANNYA","“I don’t think that’s what the note means…”"],
    hints:[
      {
        title: "I don't understand this puzzle.",
        text: "You need to find a hidden word in the note. You’ve done this before I’m sure you can do it!"
      },
      {
        title:"I have no clue what I need to do.",
        text: "Well, you might have noticed the numbers in every sentence. Try to see if you can use those numbers to figure out which letter you need in that specific sentence. If done right, you will end up with a four letter word."
      },
      {
        title: "I just want the answer!",
        text:"To find the answer, look at each sentence and find the number mentioned. Count to that number and identify the matching letter in the sentence. For example, if the sentence mentions \"four\" and you count to the fourth letter, you would select the letter 's'. Collect all these letters to form your answer. So your answer is ‘seed’."
      }
    ]
  },
];

export default merge;
