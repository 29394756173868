import Background from "../../../components/background/Background";
import GameHeader from "../../../components/GameHeader/GameHeader";
import Footer from "../../../components/footer/Footer";
import StoryCard from "../../../components/StoryCard/StoryCard";
import "./Chapter.scss";
import GameButtons from "../../../components/GameButtons/GameButtons";
import OverlayWrapper from "../../../components/OverlayWrapper/OverlayWrapper";
const Chapter = ({ chapter }) => {
  
  return (
    <>
      <Background></Background>
      <OverlayWrapper></OverlayWrapper>
      <GameHeader data={chapter}></GameHeader>
      <div className="chapterWrapper">
        <StoryCard data={chapter}></StoryCard>
        <GameButtons></GameButtons>
      </div>

      <Footer extraClass="footer--game"></Footer>
    </>
  );
};

export default Chapter;
