import "./SummerSection.scss";
const SummerSection = () => {
  return (
    <section className="summerSection">
      <figure className="summerSection__figure">
     
      </figure>
      <div className="summerSection__textWrapper">
        <h1 className="summerSection__title">Leonora's Summer Adventure</h1>
        <p className="summerSection__text">
          Get ready to re-enter the magical world with Leonora's Summer
          Adventure, our exclusive mini online escape room that will take you on
          a thrilling journey filled with secrets and riddles.
        </p>
        <p className="summerSection__text">
          When you receive a personal invitation from Leonora to visit her for
          the summer, you have no idea what you're in for. Soon, you find
          yourself caught up in a thrilling adventure where you'll need all your
          puzzle-solving skills to uncover the mysteries that lie ahead.
        </p>
        <p className="summerSection__text">
          Donate any amount to our Paypal and gain access to this immersive and
          mysterious experience. 
        </p>
        <p className="summerSection__text">
          Are you ready to put your magic (solving) skills to the test?
        </p>
        <a
          href="#id"
          className="heroSection__button heroSection__button--primary"
        >
          <p>Donate now!</p>
        </a>
      </div>
    </section>
  );
};

export default SummerSection;
