import { useDispatch, useSelector } from "react-redux";
import GameCard from "../GameCard/GameCard";
import { replaceYNWithNameOfPlayer } from "../../helpers/customStringHelpers";
import { useEffect, useState } from "react";
import { resetAction } from "../../redux/actions/hints";
import { Link } from "react-router-dom";
const StoryCard = ({ data }) => {
  const nameOfPlayer = useSelector((state) => state.nameOfPlayer);
  const [paragraphs, setParagraphs] = useState(null);
  const uD = useDispatch();

  useEffect(() => {
    let pTBR = data.paragraphs.map((text) => {
      if (text === "[---STRIPE---]") {
        return <div className="stripeWrapper"><hr className="stripe"></hr></div>
      }
      return (
        <p className="gameCard__text">
          {replaceYNWithNameOfPlayer(text, nameOfPlayer)}
        </p>
      );
    });

    setParagraphs(pTBR);
    console.info(resetAction());
    uD(resetAction());
  }, [data])



  return (
    <GameCard type="Chapter" data={data}>
      <div className="gameCard__textWrapper"> {paragraphs} </div>
      <div className="gameCard__submitWrapper">
        <Link to={data.next}>
          <a className="submit submit--yellow">Continue</a>
        </Link>
      </div>


    </GameCard>
  );
};

export default StoryCard;
