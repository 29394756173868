import { combineReducers } from "redux";
import {checkParchment} from "./helpers/helpers";
const parchmentOverlay = (state = false, action) => {
  switch (action.type) {
    case "PARCHMENTOVERLAY":
      return !state;
    default:
      return state;
  }
};

const potionOverlay = (state = false, action) => {
  switch (action.type) {
    case "POTIONOVERLAY":
      return !state;
    default:
      return state;
  }
}

const hintsOverlay = (state = false, action) => {
  switch(action.type){
    case "HINTSOVERLAY":
      return !state;
    default:
      return state;
  }
}

const parchmentPiecesObtained = (state = 0, action) => {
  switch (action.type) {
    case "PARCHMENTOBTAINED":
      if(checkParchment(state,action.payload)){
        return action.payload;
      }
      return state;
    default:
      return state;
  }
};

const potionObtained = (state = false, action) =>{
  switch(action.type){
    case "POTIONOBTAINED":
      return true;
    default:
      return state;
  }
}

const canPlayerUsePotion = (state = false, action) =>{
  switch(action.type){
    case "USEPOTION":
      return action.payload;
    default:
      return state;
  }
}

const nameOfPlayer = (state = "Player", action) => {
  switch(action.type){
    case "NAMEOFPLAYER":
      return action.payload;
    default:
      return state;
  }
}

const hints = (state = [], action) =>{
  switch(action.type){
    case "HINTS":
      return action.payload;
    default: 
    return state;
  }
}
export default combineReducers({ parchmentOverlay, hints, potionOverlay, hintsOverlay, parchmentPiecesObtained, potionObtained, nameOfPlayer, canPlayerUsePotion});
