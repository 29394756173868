import { useState } from "react";
import Review from "../../components/review/Review";
import reviewHelper from "../../helpers/reviewHelper";
import { REVIEWPAGES } from "../../data/globals";
import "./Reviews.scss";
import imgHelper from "../../helpers/imgHelper";
const Reviews = () => {
  const buttonsToBeRendered = [];
  const [active, setActive] = useState(0);

  const paginationClicked = (id) => {
    setActive(id);
  };

  for (let i = 0; i < REVIEWPAGES; i++) {
    buttonsToBeRendered.push(
      <button
        id={i}
        onClick={() => paginationClicked(i)}
        className="reviews__pagination"
      ></button>
    );
  }

  buttonsToBeRendered[active] = (
    <button className="reviews__pagination reviews__pagination--active"></button>
  );

  const setPaginationByButton = (buttonPressed) => {
    switch (buttonPressed) {
      case "LEFT":
        if(active === 0){
          setActive(REVIEWPAGES - 1);
          return;
        }
        setActive(active - 1);
        return;
      case "RIGHT":
        if(active === REVIEWPAGES - 1){
          setActive(0);
          return;
        }
        setActive(active + 1);
        return;
      default:
        return;
    }
  };
  return (
    <section className="reviews">
      <div className="reviews__wrapper">
        <button
          onClick={() => setPaginationByButton("LEFT")}
          className="reviews__button reviews__button--left"
        >
          <img
            src={imgHelper("CHEVRONLEFT").img}
            alt={imgHelper("CHEVRONLEFT").alt}
          />
        </button>
        <ul className="reviews__list">
          {
            window.innerWidth >= 800 ?  reviewHelper.map((review) => {
              if (review.page === active + 1) {
                return <Review review={review} />;
              }
            }): reviewHelper.map(review => {
              return <Review review={review} />
            })
          }
        
        </ul>
        <button
          onClick={() => setPaginationByButton("RIGHT")}
          className="reviews__button reviews__button--right"
        >
          <img
            src={imgHelper("CHEVRONRIGHT").img}
            alt={imgHelper("CHEVRONRIGHT").alt}
          />
        </button>
      </div>
      <div className="reviews__paginationWrapper">{buttonsToBeRendered}</div>
    </section>
  );
};

export default Reviews;
