import { Routes, Route } from "react-router-dom";
import AboutPage from "../pages/aboutpage/AboutPage";
import Homepage from "../pages/homepage/Homepage";
import "./App.scss";
import GameStart from "../components/GameStart/GameStart";
import Intro from "../game/Intro/Intro";
import ChapterRouter from "../game/chapters/ChapterRouter";
import DialogueRouter from "../game/Dialogue/DialogueRouter";
import PuzzleRouter from "../game/Puzzle/PuzzleRouter";
import MergePagesRouter from "../game/MergePages/MergePagesRouter";
import ItemObtainedRouter from "../game/ItemObtained/ItemObtainedRouter";
import InterActivesRouter from "../game/Interactives/InteractivesRouter";
import Certificate from "../game/Certificate/Certficate";
import { usePlayerName } from "../customHooks/usePlayerName";
const App = () => {
  const playerName = usePlayerName();
  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/start" element={<GameStart />}></Route>
        <Route path="/tips" element={<Intro />}></Route>
        <Route path="/chapter/:chapter" element={<ChapterRouter/>}></Route>
        <Route path="/chapter/:chapter/part/:part" element={<ChapterRouter/>}></Route>
        <Route path="/item-obtained/:item" element={<ItemObtainedRouter />}></Route>
        <Route path="/dialogue/:dialogue" element={<DialogueRouter/>}></Route>
        <Route path="/puzzle/:puzzle" element={<PuzzleRouter/>}></Route>
        <Route path="/merge/:number" element={<MergePagesRouter/>}></Route>
        <Route path="/interactive/:number" element={<InterActivesRouter/>}></Route>
        <Route path="/certificate" element={<Certificate/>}></Route>
        <Route path="*" element={<Homepage />}></Route>
        );
      </Routes>
    </>
  );
};

export default App;
