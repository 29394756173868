import { useEffect } from "react";
import { loadSomething } from "../helpers/storageHelper";
import { useDispatch } from "react-redux";
export const usePlayerName = () => {
    const playerNameFromStorage = loadSomething("PLAYERNAME");
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(playerNameFromStorage !== null){
            dispatch({type: "NAMEOFPLAYER", payload: playerNameFromStorage})
        }
    },[])
}