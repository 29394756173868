import PUZZLE_1 from "../img/puzzles/puzzle1/puzzle-1-1.webp";
import PUZZLE_2 from "../img/puzzles/puzzle1/puzzle-1-2.webp";
import PUZZLE_3 from "../img/puzzles/puzzle1/puzzle-1-3.webp";
import PUZZLE_2_1 from "../img/puzzles/puzzle2/puzzle2_part1.webp";
import PUZZLE_2_2 from "../img/puzzles/puzzle2/puzzle2_part2.webp";
import PUZZLE_3_1 from "../img/puzzles/puzzle3/puzzle3_part1.webp";
import PUZZLE_4_1 from "../img/puzzles/puzzle4/puzzle4_part1.webp";
import PUZZLE_4_2 from "../img/puzzles/puzzle4/puzzle4_part2.webp";
import PUZZLE_7_1 from "../img/puzzles/puzzle7/puzzle7_part1.webp";
import PUZZLE_7_2 from "../img/puzzles/puzzle7/puzzle7_part2.webp";
import PUZZLE_7_3 from "../img/puzzles/puzzle7/puzzle7_part3.webp";
import PUZZLE_8 from "../img/puzzles/puzzle8/handreading.webp";
import PUZZLE_9 from "../img/puzzles/puzzle9/puzzle9.webp";
import PUZZLE_10 from "../img/puzzles/puzzle10/puzzle10.webp";
import COLLOSHOO from "../img/duel/Colloshoo.webp";
import FLIPENDO from "../img/duel/Flipendo.webp";
import IMPEDIMENTA from "../img/duel/Impedimenta.webp";
import LEVICORPUS from "../img/duel/Levicorpus.webp";
import LOCOMOTORWIBBLY from "../img/duel/LocomotorWibbly.webp";
import PETRIFICUSTOTALUS from "../img/duel/PetrificusTotalus.webp";
import REDUCTO from "../img/duel/Reducto.webp";
import STUPEFY from "../img/duel/Stupefy.webp";
import TARANTALLEGRA from "../img/duel/Tarantallegra.webp";
import TITILLANDO from "../img/duel/Titillando.webp";
import CAULDRON from "../img/game/cauldron.png";

const puzzleHelper = (neededPuzzle) => {
  switch (neededPuzzle) {
    case "PUZZLE1-1": {
      return {
        img: PUZZLE_1,
        alt: "",
      };
    }
    case "PUZZLE1-2": {
      return {
        img: PUZZLE_2,
        alt: "",
      };
    }
    case "PUZZLE1-3": {
      return {
        img: PUZZLE_3,
        alt: "",
      };
    }
    case "PUZZLE2-1": {
      return {
        img: PUZZLE_2_1,
        alt: "",
      };
    }
    case "PUZZLE2-2": {
      return {
        img: PUZZLE_2_2,
        alt: "",
      };
    }
    case "PUZZLE3-1": {
      return {
        img: PUZZLE_3_1,
        alt: "",
      };
    }
    case "PUZZLE4-1": {
      return {
        img: PUZZLE_4_1,
        alt: "",
      };
    }
    case "PUZZLE4-2": {
      return {
        img: PUZZLE_4_2,
        alt: "",
      };
    }
    case "PUZZLE7-1": {
      return {
        img: PUZZLE_7_1,
        alt: "",
      };
    }
    case "PUZZLE7-2": {
      return {
        img: PUZZLE_7_2,
        alt: "",
      };
    }
    case "PUZZLE7-3": {
      return {
        img: PUZZLE_7_3,
        alt: "",
      };
    }
    case "PUZZLE8": {
      return {
        img: PUZZLE_8,
        alt: "",
      };
    }
    case "PUZZLE9": {
      return {
        img: PUZZLE_9,
        alt: "",
      };
    }
    case "PUZZLE10": {
      return {
        img: PUZZLE_10,
        alt: "",
      };
    }
    case "COLLOSHOO":
      return {
        img: COLLOSHOO,
        alt: "",
      };
    case "FLIPENDO":
      return {
        img: FLIPENDO,
        alt: "",
      };
    case "IMPEDIMENTA":
      return {
        img: IMPEDIMENTA,
        alt: "",
      };
    case "LEVICORPUS":
      return {
        img: LEVICORPUS,
        alt: "",
      };
    case "LOCOMOTORWIBBLY":
      return {
        img: LOCOMOTORWIBBLY,
        alt: "",
      };
    case "PETRIFICUSTOTALUS":
      return {
        img: PETRIFICUSTOTALUS,
        alt: "",
      };
    case "REDUCTO":
      return {
        img: REDUCTO,
        alt: "",
      };
    case "STUPEFY":
      return {
        img: STUPEFY,
        alt: "",
      };
    case "TARANTALLEGRA":
      return {
        img: TARANTALLEGRA,
        alt: "",
      };
    case "TITILLANDO":
      return {
        img: TITILLANDO,
        alt: "",
      };
    case "CAULDRON":
      return { img: CAULDRON, alt: "" }

    default:
      return {
        img: TITILLANDO,
        alt: ""
      };

  }
};

export default puzzleHelper;
