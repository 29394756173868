import { Link } from "react-router-dom";
import { disableScroll, scrollToTop } from "../../helpers/scrollHelpers";
import iconHelper from "../../helpers/iconHelper";
import "./MobileNavigation.scss";
const MobileNavigation = ({ setOpenNavigation }) => {
  scrollToTop();
  disableScroll("100vh", document.getElementsByTagName("body")[0]);
  return (
    <nav className="mobileNavigationComponent">
      <div>
        <button
          onClick={() => setOpenNavigation(false)}
          className="mobileNavigationComponent__close"
        >
          <img src={iconHelper("CLOSE").img} alt={iconHelper("CLOSE").alt} />
        </button>
      </div>
      <ul className="mobileNavigationComponent__list">
        <li className="mobileNavigationComponent__item">
        <Link  onClick={() => setOpenNavigation(false)} to="/home" className="mobileNavigationComponent__item">Home</Link>

        </li>
        <li className="mobileNavigationComponent__item">
        <Link  onClick={() => setOpenNavigation(false)} to="/about" className="mobileNavigationComponent__item">About</Link>

        </li>
        <li className="mobileNavigationComponent__item">
          <a href="#id">Credits</a>
        </li>
        <li className="mobileNavigationComponent__item mobileNavigationComponent__item--donate">
          <a href="#id">Donate</a>
        </li>
      </ul>
      <ul className="mobileNavigationComponent__socials">
        <li className="mobileNavigationComponent__social">
          <img
            src={iconHelper("INSTAGRAM").img}
            alt={iconHelper("INSTAGRAM").alt}
          />
        </li>
        <li className="mobileNavigationComponent__social">
          <img
            src={iconHelper("FACEBOOK").img}
            alt={iconHelper("FACEBOOK").alt}
          />
        </li>
        <li className="mobileNavigationComponent__social">
          <img src={iconHelper("MAIL").img} alt={iconHelper("MAIL").alt} />
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavigation;
