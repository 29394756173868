import itemImageHelper from "../../helpers/itemImageHelper";
import GameCard from "../GameCard/GameCard";
import "./ItemCard.scss";
import { Link } from "react-router-dom";
const ItemCard = ({ data }) => {

    const paragraphs = data.text.map( p => {
        return <p className="itemCard__text">{p}</p>
    })
  return (
    <GameCard data={data}>
      <article className="itemCard">
        <h1 className="itemCard__title">{data.title}</h1>
        <figure className="itemCard__figure">
            <img className="itemCard__image" src={itemImageHelper(data.image).img} alt={itemImageHelper(data.image).alt} />
        </figure>
        {paragraphs}
        <div className="gameCard__submitWrapper">
        <Link to={data.next}>
          <a className="submit submit--yellow">Continue</a>
        </Link>
      </div>
      </article>
    </GameCard>
  );
};

export default ItemCard;
