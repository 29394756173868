import Background from "../../components/background/Background";
import GameHeader from "../../components/GameHeader/GameHeader";
import PuzzleCard from "../../components/PuzzleCard/PuzzleCard";
import GameButtons from "../../components/GameButtons/GameButtons";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Footer from "../../components/footer/Footer";
import { useEffect, useState } from "react";
import OverlayWrapper from "../../components/OverlayWrapper/OverlayWrapper";
import { useNavigate } from "react-router-dom";
import { removeSpaces, transformToUppercase } from "../../helpers/customStringHelpers";
import { useDispatch } from "react-redux";
const Puzzle = ({ data }) => {
  const [puzzle, setPuzzle] = useState(1);
  const [answer, setAnswer] = useState("")
  const [errorOpen, setErrorOpen] = useState("closed");
  const uN = useNavigate();
  const uD = useDispatch();

  const setAnswerFromInput = (answer) => {
    setAnswer(answer);
  }

  let ptr = data.puzzles.find((p) => {
    return p.number === puzzle;
  });
  
  useEffect(() => {
    uD({type: "HINTS", payload: ptr.hints});
    console.info("hier")
  },[puzzle])

 
  const checkAnswer = () => {
    if (transformToUppercase(removeSpaces(ptr.answer)) === transformToUppercase(removeSpaces(answer))) {
      setAnswer("");
      if (puzzle < data.puzzles.length) {
        setPuzzle(puzzle + 1);
        return;
      }
      uN(data.next);
    }
    else {
      setErrorOpen("open");
    }
  };

  const closeDialogue = () =>{
    setErrorOpen("closed");
  }


  return (
    <>
      <Background></Background>
      <OverlayWrapper></OverlayWrapper>
      <GameHeader data={ptr}></GameHeader>
      <div className="chapterWrapper">
        <PuzzleCard answer={answer} setAnswerFromInput={setAnswerFromInput} checkAnswer={checkAnswer} data={ptr}></PuzzleCard>
        <GameButtons></GameButtons>
      </div>
      <ErrorMessage closeDialogue={closeDialogue} eOpen={errorOpen} ptr={ptr}></ErrorMessage>

      <Footer extraClass="footer--game"></Footer>
    </>
  );
};

export default Puzzle;
