import PrimaryButton from "../PrimaryButton/PrimaryButton";
import UniqueSellingPoints from "../usp/UniqueSellingPoints";
import imgHelper from "../../helpers/imgHelper";
import textHelper from "../../helpers/textHelper";
import "./HeroSection.scss";

const HeroSection = () => {
  return (
    <section className="heroSection">
      <figure className="heroSection__logo">
        <img
          src={imgHelper("LOGOWITHHEAD").img}
          alt={imgHelper("LOGOWITHHEAD").alt}
        />
      </figure>
      <h1 className="heroSection__title">{textHelper("HOMEPAGE_TITLE")}</h1>
      <p className="heroSection__text">{textHelper("HOMEPAGE_DESC")}</p>
      <div className="heroSection__linkWrapper">
        <a
          href="#id"
          className="heroSection__button heroSection__button--secondary"
        >
          <p>Play year 1</p>
        </a>
        <PrimaryButton />
      </div>
      <UniqueSellingPoints></UniqueSellingPoints>
    </section>
  );
};

export default HeroSection;
