const puzzles = [
  {
    puzzle: 1,
    next: "/chapter/2",
    puzzles: [
      {
        number: 1,
        puzzle: 1,
        part: 1,
        progress: 6,
        title: "Herbology class: Puzzle 1",
        previous: "/dialogue/1",
        next: 1,
        background: "table",
        img: "PUZZLE1-1",
        text: 'You need to take a look at your textbook. "One Thousand Magical Herbs and Fungi" and find the page about the "Xanthoceras". On which page will you find the answer?',
        answer: "157",
        errorMessage: [
          "PROFESSOR MARIGOLD", "“Unfortunately that is not correct, try looking at your textbook ‘One Thousand Magical Herbs and Fungi’ for the answer. Maybe your notes can help you?”"
        ],
        hints:[
          {
            title: "I don't understand this puzzle.",
            text: "You have come to the right place, or should I say the right hat. I can help you solve these puzzles along the way. To find the page that will answer the professor's question, you need to solve this puzzle. Take a look at the plants in your textbook and compare them to Finnian’s notes carefully."
          },
          {
            title:"I have no clue what I need to do.",
            text: "Clearly, ahem, well you need to take a look at the three plants that you can also find in Finnian’s notes. Then look at your textbook to see in which month these three need to be planted. When you know the correct months, it's just a matter of turning that into the correct page of your book."
          },
          {
            title: "I just want the answer!",
            text: "The correct answer is page ‘157’. When you take the three plants in Finnian’s notes you will recognize them on the page of your textbook. On Finnian’s notes, it says that the month of the plants is important. The first one is the Zamioculas Zamiifolio which is planted in July. The second one is the Monstera Adansonii which is planted in May. The third one is the Philodendron Xanadu which is planted in January. Now all you need to do is put them in chronological order and you will get January, May and July. When you turn these months into numbers you will get 1-5-7 or 157."
          }
        ]
      },
      {
        number: 2,
        puzzle: 1,
        part: 2,
        progress: 8,
        title: "Herbology class: Puzzle 2",
        previous: "/puzzle/1/1",
        next: 2,
        background: "table",
        img: "PUZZLE1-2",
        text: "Oh no! In turning the page you’ve spilt ink all over your textbook. There must be some sort of spell to get rid of it. What spell do you need to use?",
        answer: "157",
        errorMessage: [
          "PROFESSOR MARIGOLD", "“Hmm it looks like the ink is not coming out of there.”"
        ],
        hints:[
          {
            title: "I don't understand this puzzle.",
            text:"In order to remove the ink you have clumsily spilled all over your textbook, you will need the right cleaning spell*. Take a closer look at the words written in your textbook, it might prove useful. *this spell is not an official spell in the HP universe."
          },
          {
            title:"I have no clue what I need to do.",
            text:"Is this your first time taking a look at your textbook? Anyway, how about this: you need to find a hidden word in the textbook. Take a closer look at the sentences to see if you can find any clues as to which letters you might need for the spell. You’ve probably done this before, I’m sure you can do it."
          },
          {
            title:"You underestimated me. I still don't get it.",
            text: "You do realise we’re only at puzzle 1 right? Alright, calm down, I’m just kidding, sort of. You need to take a look at the first letter of every sentence. So for example the first sentence starts with the word ‘Each’ so you might want to start your spell off with the letter ‘E’. Continue this throughout the entire paragraph and a spell should appear."
          },
          {
            title: "I just want the answer!",
            text: "I don’t think this will win you any house points, but sure, the answer is ‘Extergimus’.",
          }
        ]
      },
      {
        number: 3,
        puzzle: 1,
        part: 3,
        progress: 10,
        title: "Herbology class: Puzzle 3",
        background: "table",
        img: "PUZZLE1-3",
        text: "Now that you’ve removed the ink stain, you can finally answer Professor Marigold’s question. How many days of sun a year does the Xantheroceras need?",
        answer: "157",
        errorMessage: [
          "PROFESSOR MARIGOLD", "“Look again dear, that doesn’t seem to be right just yet.”"
        ],
        hints:[
          {
            title: "I don't understand this puzzle.",
            text: "You have succesfully removed the ink stain, so there’s new information available in your textbook. I’m sure this will lead you to the correct answer to the question ‘How many days of sun does the ‘Xanthoceras’ need?’."
          },
          {
            title:"I have no clue what I need to do.",
            text: "Don’t tell me you’re bad at maths? If you read the text it will tell you that the Xanthoceras typically grow between the 14th of March till the 24th of May. All you need to do is figure out the amount of days in between those dates."
          },
          {
            title: "I just want the answer!",
            text: "I mean you could have used a calculator but alright, between the 14th of March and the 24th of May there are 71 days, so your answer is ‘71’."
          }
        ]
      },
    ],
  },
  {
    puzzle: 2,
    next: "/dialogue/6",
    puzzles: [
      {
        number: 1,
        puzzle: 1,
        title: "Runes: Puzzle 3",
        background: "wall",
        img: "PUZZLE2-2",
        text: "What secret are the runes hiding?",
        answer: "157",
        progress: 25,
        errorMessage: ["TERRY","“I don’t think that’s right.”"],
        hints:[
          {
            title: "I don't understand this puzzle.",
            text: "Picture this: a 'connect the dots' puzzle, but with ancient runes. A challenge fit for a puzzle master like yourself. Don't fret, I'm sure you have it in you to solve this puzzle."
          },
          {
            title:"I have no clue what I need to do.",
            text: "I was wrong... Well to solve this puzzle, you will need to connect each rune to the next one in the correct sequence. There are three distinct 'connect the dots' challenges within this puzzle, and the result of each is a number. You will need to find the three numbers hidden within these runes?"
          },
          {
            title: "I just want the answer!",
            text: "The correct answer is ‘428’."
          }
        ]
      },
    ],
  },
  {
    puzzle: 3,
    next: "/dialogue/10",
    puzzles: [
      {
        number: 1,
        puzzle: 1,
        title: "Coins: Puzzle 2",
        background: "library--chest--open",
        img: "PUZZLE3-1",
        text: "Which magical creature might give you a clue?",
        answer: "157",
        progress: 36,
        errorMessage: ["LEONORA","“I don’t think that creature will help us.”"],
        hints:[
          {
            title: "I don't understand this puzzle.",
            text: "Puzzled, huh? Well these coins cannot be used to buy yourself some Treacle Tarts. What you need to do is pick the right one, maybe the prophecy parchment can help you out?"
          },
          {
            title:"I have no clue what I need to do.",
            text: "You're still clueless? Well, if you take a look at the prophecy parchment it should have tell you that 'coin = 7'. So you need to figure out which coin leads you to the number ‘7’. Once you figure it out, choose the magical creature as your answer."
          },
          {
            title: "I just want the answer!",
            text: "It’s basic maths honestly. But very well, the right coin is the centaur coin because 1+3+1+2=7. So your answer is ‘centaur’."
          }
        ]
      },
    ],
  },
  {
    puzzle: 4,
    next: "/interactive/2",
    puzzles: [
      {
        number: 1,
        puzzle: 1,
        title: "Potions class: Puzzle 1",
        background: "potions--darker",
        img: "PUZZLE4-1",
        text: "What is the missing ingredient you need for you hair-raising potion?",
        answer: "157",
        progress: 44,
        errorMessage:["PROFESSOR ROOKS", "“Maybe try thinking outside the cauldron? Perhaps you need to add a pinch of creativity to unlock the full potential of this hair-raising concoction.”"],
        hints:[
          {
            title: "I don't understand this puzzle.",
            text: "The professor has left some clues on the board to help you find your missing ingredient. See if you can find a word when you correspond the colours of the potions to the letters on the board."
          },
          {
            title:"I have no clue what I need to do.",
            text: "Feeling lost? You need to use the colours of the potions to figure out which letter you need. Work from the outside to the inside (so bottle shape first and then the colour of the label). Stick to the order on the board to know which potion you need first. So for example the first potion on the board is the second potion to the right on your notes, the outside is purple and the label is pink, now look at the board."
          },
          {
            title: "I just want the answer!",
            text: "Just want the answer handed to you? Fine, fine. Your missing ingredient is 'crushed beetles'. If you had taken the first potion on the board and used the purple and pink colours you would have gotten the ‘c’ and the ‘r’. If you had continued to follow the potions you would have gotten these words."
          }
        ]
      },
    ],
  },
  {
    puzzle: 6,
    next: "/item-obtained/3",
    puzzles: [
      {
        number: 1,
        puzzle: 1,
        title: "Potions class: Puzzle 3",
        background: "potions--darker",
        img: "CAULDRON",
        text: "The bubbles may give you a hint for the reward, what do they say?",
        answer: "157",
        progress: 47,
        errorMessage:["PROFESSOR ROOKS","“Lucky for you, I absolutely love watching potions bubble. I could watch them all day…”",],
        hints:[
          {
            title: "I don't understand this puzzle.",
            text: "This puzzle is dead simple. All you need is the ability to read, can you do it?"
          },
          {
            title:"I have no clue what I need to do.",
            text: "Alright, my apologies for overestimating you. You need to make a word out of the letters that keep showing up in the bubbles. Maybe try writing down the individual letters so you can figure out what word they’re supposed to spell."
          },
          {
            title: "I just want the answer!",
            text: "I was kidding! The word you’re looking for is ‘invisibility’."
          }
        ]
      },
    ],
  },
  {
    puzzle: 7,
    next: "/dialogue/13",
    puzzles: [
      {
        number: 1,
        puzzle: 1,
        title: "Fawleys Painting: Puzzle 1",
        background: "staircase--darker",
        img: "PUZZLE7-1",
        text: "I would like you to solve one of my favourite riddles. Read this riddle carefully, dissect it, and analyse it until it begs for mercy. Let me know when you think you have the answer.",
        answer: "157",
        progress: 54,
        errorMessage:["PROFESSOR FAWLEY","“That answer was about as helpful as a chocolate teapot. I suggest you try again.”"],
        hints:[
          {
            title: "I don't understand this puzzle.",
            text: "This is just a riddle, I don’t think you need my help on this one."
          },
          {
            title: "I just want the answer!",
            text: "Guess I was wrong. The answer is ‘time’."
          }
        ]
      },
      {
        number: 2,
        puzzle: 2,
        title: "Fawleys Painting: Puzzle 2",
        background: "staircase--darker",
        img: "PUZZLE7-2",
        text: "Well done, it seems like you aren’t an incompetent lot after all. However, I wonder, but can you figure out my favourite pastime?",
        answer: "157",
        progress: 56,
        errorMessage:["PROFESSOR FAWLEY","“I'm starting to wonder if you're trying to stump me with creative ways to answer the question incorrectly.”"],
        hints:[
          {
            title: "I don't understand this puzzle.",
            text: "This puzzle is a word maze. Just try to get to the middle from one of two starting points and write down the letters you come across along the way."
          },
          {
            title:"I have no clue what I need to do.",
            text: "Have you tried starting from the top of the word maze?"
          },
          {
            title: "I just want the answer!",
            text: "Alright, the answer here is ‘game of gobstones’."
          }
        ]
      },
      {
        number: 3,
        puzzle: 3,
        title: "Fawleys Painting: Puzzle 3",
        background: "staircase--darker",
        img: "PUZZLE7-3",
        text: "Very good, but let’s see if you’ve really got what it takes.",
        answer: "157",
        progress:58,
        errorMessage:["PROFESSOR FAWLEY","“Wrong answer, but well, at least you're consistent.”"],
        hints:[
          {
            title: "I don't understand this puzzle.",
            text: "This puzzle is called a rebus. You need to replace parts of the existing words with the new letters that you can find on the paper."
          },
          {
            title:"I have no clue what I need to do.",
            text: "The drawings represent a Snake, an Owl, a Scar and Tape."
          },
          {
            title: "I just want the answer!",
            text: "Alright, the answer here is ‘Sneakoscope’."
          }
        ]
      },
    ],
  },
  {
    puzzle: 8,
    next: "/dialogue/16",
    puzzles: [
      {
        number: 1,
        puzzle: 1,
        title: "Divination Classroom: Puzzle 1",
        background: "divination",
        img: "PUZZLE8",
        text: "This professor must have been raised by Muggles, because the cabinet is locked by an ordinary four digit lock. Though strangely Alohomora still doesn’t seem to work… Can you figure out the right 4-digit combination?",
        answer: "157",
        progress:68,
        errorMessage:["CRYSTAL","“Alas, my cabinet remains locked by the ordinary four-digit lock, even my foresight cannot unlock it. Fear not, for I can see that the answer is within your reach. Take a deep breath, concentrate, and try again.”"],
        hints:[
          {
            title: "I don't understand this puzzle.",
            text:"This puzzle is about deciphering the symbols of the hand on the right page of the book and finding the correct order by looking at the left page.",
          },
          {
            title:"I have no clue what I need to do.",
            text: "Not to worry, dearie. The symbols you see on the hand on the right page of the book are actually mirrored numbers. You will know which numbers you need when you look at the order of the planets on the notes and compare them to the placement on both hands."
          },
          {
            title: "I just want the answer!",
            text: "The answer you’re looking for is ‘2937’. The planets written on the note tell you the correct order is Saturn, Mercury, Jupiter and Venus. If you take a look at the hand on the left page you can find the placement of these planets and on the right page you’ll find which placement corresponds with which symbol. If you decipher the symbols, which are mirrored numbers, you're left with ‘9’, ‘2’, ‘3’ and ‘7’. Now you just have to put them in the order of the planets on the note and you’re left with 2937."
          }
        ]

      },
    ],
  },
  {
    puzzle: 9,
    next: "/dialogue/17",
    puzzles: [
      {
        number: 1,
        puzzle: 1,
        title: "Divination Classroom: Puzzle 2",
        background: "divination",
        img: "PUZZLE9",
        text: "Slowly flashing images start to appear in the blue fog behind the glass. It’s almost like looking at a distorted version of reality. Can you help Terry decipher the vision in the crystal ball?",
        answer: "157",
        progress:72,
        errorMessage:["CRYSTAL","“It seems that Terry's vision remains shrouded in mystery. Focus your magical abilities and try to decipher the vision with precision. Remember, the truth may be elusive, but perseverance shall prevail.”"],
        hints:[
          {
            title: "I don't understand this puzzle.",
            text: "The image in the crystal ball oddly resembles a clock. Maybe Finnian’s notes can help you figure out what time the clock is trying to tell you. *You’re looking for a four-digit answer.",
          },
          {
            title:"I have no clue what I need to do.",
            text: "If you take a look at Finnian’s notes you will see some arrows that resemble the hands of a clock. If you follow the right order and decipher the symbols using the smaller crystal ball you will end up with an answer. Yet you might have to take a look at your prophecy parchment for help."
          },
          {
            title: "I just want the answer!",
            text: "It’s about TIME you asked. Get it? Anyway, the correct answer here is ‘04:35’.",
          }
        ]
      },
    ],
  },
  {
    puzzle: 10,
    next: "/dialogue/18",
    puzzles: [
      {
        number: 1,
        puzzle: 1,
        title: "Divination Classroom: Puzzle 3",
        background: "divination",
        img: "PUZZLE10",
        text: "What could the ouija board be trying to tell you?",
        answer: "157",
        progress: 75,
        errorMessage:["CRYSTAL","“The answer you provided doesn’t seem to be right. The mysterious messages from beyond may remain an enigma for you just a little longer.”"],
        hints:[
          {
            title: "I don't understand this puzzle.",
            text: "You want to decipher what the crystal ball is trying to tell you. Maybe Finnian’s notes can help you with choosing the right letters in the crystal ball.",
          },
          {
            title:"I have no clue what I need to do.",
            text:"If you take a closer look at the planchette on the ouija board you will find a hidden message that says: +7. You need to add seven letters to the letters you’ve found on Finnian’s notes and you will get your answer. So for example A + 7 = H.", 
          },
          {
            title: "I just want the answer!",
            text: "Luckily I have just that, the answer you’re looking for is ‘expergi seeds’.",
          }
        ]
      },
    ],
  },
  {
    puzzle: 11,
    next: "/dialogue/20",
    puzzles: [
      {
        number: 1,
        puzzle: 1,
        title: "The Duel",
        background: "forest",
        img: "PUZZLE10",
        text: "Oh no! Galen’s little sister Serena is turning towards you, you should stop her by knocking her back!",
        answer: "157",
      },
      {
        number: 2,
        puzzle: 2,
        title: "The Duel",
        background: "forest",
        img: "PUZZLE10",
        text: "One of their friends is running towards you at full speed, try to slow him down!",
        answer: "157",
      }, 
    ],
  },
];

export default puzzles;
